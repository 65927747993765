<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <div>
          <router-link :to="'/facturadetail/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
            </div>
          </router-link>
          <router-link :to="'/facturadetailtable/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">Fakturani kurish</span>
            </div>
          </router-link>
          <router-link :to="'/completedworks/' + index.id">
            <div class="w-100 navi-link">
              <span class="navi-icon">
                <i class="flaticon2-file-1 text-success"></i>
              </span>
              <span class="navi-text">Bajarilgan ishlarni kurish</span>
            </div>
          </router-link>
          <a href="#" @click="getExcel(index.id)" class="navi-link">
            <span class="navi-icon">
              <i class="flaticon2-graph-1"></i>
            </span>
            <span class="navi-text">Excel</span>
          </a>
        </div>
        <!--         <v-btn
                  dark
                  style="margin: 10px auto 10px 10px"
                  small
                  @click="snackbar = true"
                >
                  <span class="navi-icon mr-2">
                    <i class="flaticon-delete-1 text-white ml-auto"></i> </span
                  >O'chirish
                </v-btn>
               <v-snackbar color="red" :centered="true" :top="true" v-model="snackbar">-->
        <!--          Fakturani o'chirish-->
        <!--          <template v-slot:action="{ attrs }">-->
        <!--            <v-btn text v-bind="attrs" @click="$emit('deleteFaktura',index.id)">-->
        <!--              Tasdiqlash-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--        </v-snackbar>-->
        <a @click="$emit('deleteFaktura', index.id)" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-delete-1 text-danger"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DELETE') }}</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
// import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from 'axios'
export default {
  components: {},
  name: 'ZayavkaAction',
  data: () => ({
    snackbar: false
  }),
  props: {
    btnClass: String,
    index: Object,
    id: Number
  },
  computed: {},
  methods: {
    getExcel(id) {
      axios
        .get('contracts/clientcontracts/factures/export_excel/' + id + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Faktura.xlsx')
          document.body.appendChild(link)
          link.click()
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted() {}
}
</script>

<style scoped>
.image-container {
  width: 100%;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
