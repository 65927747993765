<template>
  <div class="card-body">
    <!-- <pre>{{ allZayavkaList }}</pre> -->
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="allZayavkaList"
      :items-per-page="itemsPerPage"
      hide-default-footer
      single-select
      @click:row="rowClick"
      item-key="id"
      no-data-text="Maʼlumot kiritilmagan"
      class="elevation-1 text-center row-pointer fixed-header"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.action`]="{ item }">
        <FacturaAction :index="item" />
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | formatDate }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        @input="onPageChange"
        :length="pageCount"
      ></v-pagination>
      <div class="row justify-content-end mt-2">
        <div class="mr-4">
          <p
            style="font-size: 15px"
            class="text-dark font-bold label label-lg label-light-success label-inline"
          >
            Soni: {{ getallFacturaCount }}
          </p>
        </div>
        <div>
          <p
            style="font-size: 15px"
            class="text-dark font-bold label label-lg label-light-success label-inline"
          >
            Umumiy summa:
            {{ (allFacturaTotalSumm && numberWithSpaces(allFacturaTotalSumm)) || 0 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FacturaAction from "@/view/content/dropdown/FacturaAction";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
import { numberWithSpaces, takeFilledKeyValue } from "@/utils";

export default {
  props: {
    filterProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      page: +this.$route.params.id || 1,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      count: 0,
      totalPriceWithNds: 0,
    };
  },

  components: {
    FacturaAction,
  },
  watch: {
    $route: {
      handler(val) {
        this.page = +val.params.id || 1;
        this.getPostData(this.page);
      },
      // immediate: true
    },
  },

  computed: {
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("TABLE_HEADER.CLIENT"), value: "client_name" },
        { text: this.$t("TABLE_HEADER.NUMBER"), value: "reg_number" },
        {
          text: this.$t("TABLE_HEADER.CONTRACT"),
          value: "contract_reg_number",
        },
        { text: this.$t("TABLE_HEADER.INN"), value: "client_inn" },
        { text: this.$t("TABLE_HEADER.DATE"), value: "oper_date" },
        {
          text: this.$t("TABLE_HEADER.PRICE"),
          value: "get_factura_amount.price",
        },
        { text: this.$t("TABLE_HEADER.NDS"), value: "get_factura_amount.nds" },
        {
          text: this.$t("TABLE_HEADER.PRICE_WITH_NDS"),
          value: "get_factura_amount.price_with_nds",
        },
        {
          text: this.$t("TABLE_HEADER.ITEM"),
          value: "get_factura_amount.count",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
    isLoading() {
      return this.$store.state.requests.isLoading;
    },

    allZayavkaList() {
      return this.$store.state.requests.allFacturas?.results || [];
    },

    pageCount() {
      return Math.ceil((this.$store.state.requests.allfacturaTotalNum || 1) / 10);
    },

    allFacturaTotalSumm() {
      return this.$store.state.requests.allfacturaTotalSumm;
    },
    getallFacturaCount() {
      return this.$store.state.requests.allfacturaTotalNum;
    },
  },

  methods: {
    numberWithSpaces,
    onPageChange(pageNumber) {
      this.getPostData(pageNumber);
    },
    getPostData(value, params) {
      let value2 = value || this.page;
      let routeParams = {};

      const { oper_date__year, oper_date__month } = this.$route.query;

      if (!this.filterProps.oper_date_before && !this.filterProps.oper_date_after) {
        routeParams.oper_date__year = oper_date__year;
        routeParams.oper_date__month = oper_date__month;
      }

      this.$store.dispatch("getAllFacturasPage", {
        page: value2,
        params: takeFilledKeyValue({
          ...(this.filterProps || {}),
          ...routeParams,
          ...params,
        }),
      });
    },
    rowClick(item, row) {
      this.counter++;
      if (this.counter == 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
          row.select(true);
          this.selectedId = item.id;
        }, 200);
      } else {
        clearTimeout(this.timer);
        self.counter = 0;
        this.$router.push("/facturadetail/" + item.id + "/");
        row.select(false);
      }
    },
  },

  mounted() {
    this.$router
      .push({
        name: "Allfactura Page",
        query: {
          oper_date__year: this.$route.query.oper_date__year || currentYear,
          oper_date__month: this.$route.query.oper_date__month || currentMonth,
        },
      })
      .catch(() => {
        this.getPostData();
      });
  },
};
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}

.payment__name {
  font-weight: bold;
}

.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}

.v-application .fixed-header {
  height: auto !important;
  overflow-y: unset !important;
}
</style>
